<template>
    <div>
        <div class="my-click" v-loading="loadingData">
            <el-row :gutter="20">
                <el-divider content-position="left">
                    Klinika ma'lumotlari
                </el-divider>
                <el-col :span="18">
                    <el-row :gutter="20">
                        <el-col :span="12">
                            <div class="itme-info-click">
                                <b> {{ $t("message.myNameClick") }}:</b>
                                <span> {{ model.legal_entity_name }}</span>
                            </div>
                        </el-col>
                        <!-- end col -->

                        <el-col :span="12">
                            <div class="itme-info-click">
                                <b> {{ $t("message.brand_name") }}:</b>
                                <span> {{ model.brand_name }}</span>
                            </div>
                        </el-col>
                        <!-- end col -->

                        <el-col :span="12">
                            <div class="itme-info-click">
                                <b>
                                    {{
                                        $t("message.medicalOrganizationTypes")
                                    }}:</b
                                >
                                <span>
                                    {{
                                        model.medical_organization_type_name
                                    }}</span
                                >
                            </div>
                        </el-col>
                        <!-- end col -->

                        <el-col :span="12">
                            <div class="itme-info-click">
                                <b> {{ $t("message.inn") }}:</b>
                                <span> {{ model.inn }}</span>
                            </div>
                        </el-col>
                        <!-- end col -->

                        <el-col :span="12">
                            <div class="itme-info-click">
                                <b> {{ $t("message.tel_number") }}:</b>
                                <span> {{ model.phone_number }}</span>
                            </div>
                        </el-col>
                        <!-- end col -->

                        <el-col :span="12">
                            <div class="itme-info-click">
                                <b> {{ $t("message.email") }}:</b>
                                <span> {{ model.email }}</span>
                            </div>
                        </el-col>
                        <!-- end col -->

                        <el-col :span="12">
                            <div class="itme-info-click">
                                <b> {{ $t("message.regions") }}:</b>
                                <span>
                                    {{
                                        model.work_region
                                            ? model.work_region.region_name
                                            : ""
                                    }}</span
                                >
                            </div>
                        </el-col>
                        <!-- end col -->

                        <el-col :span="12">
                            <div class="itme-info-click">
                                <b> {{ $t("message.cityDistricts") }}:</b>
                                <span>
                                    {{
                                        model.work_city_district
                                            ? model.work_city_district.name
                                            : ""
                                    }}</span
                                >
                            </div>
                        </el-col>
                        <!-- end col -->

                        

                        <el-col :span="12">
                            <div class="itme-info-click">
                                <b> {{ $t("message.address") }}:</b>
                                <span> {{ model.work_address }}</span>
                            </div>
                        </el-col>
                        <!-- end col -->
                    </el-row>
                </el-col>
                <el-col :span="6">
                    <div class="grid-content bg-purple"> 
                        <img
                            v-if="image.id" 
                            class="img-click-logo w-100-p"
                            :src="url + '/' + image.path"
                        />
                         <img
                            v-else
                            class="img-click-logo w-100-p"
                            src="./../../../public/img/avatar_clinica.jpg"
                        />
                    </div>
                </el-col>
                <br />
                <br />
                <el-col :span="24">
                    <el-divider content-position="left">
                        Klinikaning yuridik manzili
                    </el-divider>
                    <el-row
                        :gutter="20"
                    >
                        <el-col :span="6">
                            <div class="itme-info-click">
                                <b>Viloyat: </b>
                                <span>{{model.region ? model.region.region_name : ''}}</span>
                            </div>
                        </el-col>
                        <!-- end col -->
                        <el-col :span="6">
                            <div class="itme-info-click">
                                <b>Shahar va Tuman: </b>
                                <span>{{ model.city_district ? model.city_district.name : '' }}</span>
                            </div>
                        </el-col>
                        <!-- end col -->
                        <el-col :span="6">
                            <div class="itme-info-click">
                                <b>Ro'yxatdan o'tgan vaqti: </b>
                                <span>
                                {{ model.time_of_registered }}
                                </span>
                            </div>
                        </el-col>
                        <!-- end col -->
                        <el-col :span="6">
                            <div class="itme-info-click">
                                <b>Manzil: </b>
                                <span>
                                {{ model.address }}
                                </span>
                            </div>
                        </el-col>
                        <!-- end col -->
                    </el-row>
                </el-col>
                <br />
                <br />
                <el-col :span="24">
                    <el-divider content-position="left">
                        Bank Malumotlari.
                    </el-divider>
                    <el-row
                        :gutter="20"
                        v-for="item in items"
                        :key="item.id"
                    >   
                        <el-col :span="6">
                            <div class="itme-info-click">
                                <b>Bank nomi: </b>
                                <span>{{item.bank_name ? item.bank_name : ''}}</span>
                            </div>
                        </el-col>
                        <el-col :span="6">
                            <div class="itme-info-click">
                                <b>R/S BANK: </b>
                                <span> {{ item.r_s_bank }}</span>
                            </div>
                        </el-col>
                        <!-- end col -->
                        <el-col :span="6">
                            <div class="itme-info-click">
                                <b>MFO: </b>
                                <span> {{ item.mfo }}</span>
                            </div>
                        </el-col>
                        <!-- end col -->
                        <el-col :span="6">
                            <div class="itme-info-click">
                                <b>Valyuta turi: </b>
                                <span>
                                    {{
                                        item.currency
                                            ? item.currency.type_name
                                            : ""
                                    }}</span
                                >
                            </div>
                        </el-col>
                        <!-- end col -->
                    </el-row>
                </el-col>

                <el-col :span="24" class="mt-30">
                    <el-divider content-position="left">
                        Bino inshoat ma'lumotlarni
                    </el-divider>
                    <el-row
                        :gutter="20"
                        v-for="item in building_informations_of_clinics"
                        :key="item.id"
                    >
                        <el-col :span="8">
                            <div class="itme-info-click">
                                <b> {{ $t("message.cadastre_number") }}:</b>
                                <span> {{ item.cadastre_number }}</span>
                            </div>
                        </el-col>
                        <!-- end col -->

                        <el-col :span="8">
                            <div class="itme-info-click">
                                <b> {{ $t("message.propertyOwnerships") }}:</b>
                                <span> {{ item.property_ownership ? item.property_ownership.name : '' }}</span>
                            </div>
                        </el-col>
                        <!-- end col -->

                        <el-col :span="8">
                            <div class="itme-info-click">
                                <b> {{ $t("message.document_name") }}:</b>
                                <span> {{ item.document_name }}</span>
                            </div>
                        </el-col>
                        <!-- end col -->

                        <el-col :span="8">
                            <div class="itme-info-click">
                                <b> {{ $t("message.document_number") }}:</b>
                                <span> {{ item.document_number }}</span>
                            </div>
                        </el-col>
                        <!-- end col -->

                        <el-col :span="8">
                            <div class="itme-info-click">
                                <b> {{ $t("message.document_date") }}:</b>
                                <span> {{ item.document_date }}</span>
                            </div>
                        </el-col>
                        <!-- end col -->
                    </el-row>
                </el-col>
            </el-row>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import _ from 'lodash';
export default {
    data() {
        return {
            url: process.env.VUE_APP_URL_DOCS,
            loadingData: false,
        };
    },
    mounted() {
        if (!this.loadingData) {
            this.loadingData = true;
            this.show(this.$route.params.id).then((res) => {
                this.loadingData = false;
            }).catch((err) => {
                this.loadingData = false;
            });
        }
    },
    computed: {
        ...mapGetters({
            model: "clinics/model",
            items: "clinics/items",
            building_informations_of_clinics: "clinics/building_informations_of_clinics",
        }),
        actions: function () {
            return ["edit", "delete"];
        },
        image: function () {
            let logo = {};
            let model = JSON.parse(JSON.stringify(this.model));
            if (model && !_.isEmpty(model.images)) {
                this.model.images.forEach((item, index) => {
                    if (index == 0) {
                        logo['id'] = item.id;
                        logo['path'] = item.path;
                    }
                });
                return logo;
            }
            return logo;
        }
    },
    methods: {
        ...mapActions({
            show: "clinics/show",
        }),
    },
    beforeRouteLeave (to, from, next) {
        this.$store.commit('clinics/EMPTY_MODEL');
        next();
    },
};
</script>

